<template>
    <section>
        <section class="nav-container m-t-18">
            <a @click="navActiveKey = item.key" :class="item.key == navActiveKey ? 'active' : ''" v-for="item in navMap" :key="item.key">{{item.name}}</a>
        </section>

        <section>
            <person-info v-if="navActiveKey === 1" :personnelId="pid" />
            <leave-record v-if="navActiveKey === 2" :personnelId="pid"  />
            <attendance v-if="navActiveKey === 3" :personnelId="pid" />
            <trainning v-if="navActiveKey === 4" :personnelId="pid" />
            <salary v-if="navActiveKey === 5" :personnelId="pid" />
            <warning-data v-if="navActiveKey === 6" :personnelId="pid" />
        </section>
    </section>
</template>

<script>
import Attendance from './details-component/attendance'
import personInfo from './details-component/info'
import leaveRecord from './details-component/leaveRecord'
import Trainning from './details-component/train'
import Salary from './details-component/salary'
import warningData from './details-component/warningData'
export default {
    data(){
        return{
            navMap:[
                {name:'基本信息',key:1},
                {name:'退场记录',key:2},
                {name:'考勤信息',key:3},
                {name:'安全培训',key:4},
                {name:'薪资发放',key:5},
                {name:'异常数据',key:6},
            ],
            navActiveKey:1
        }
    },
    components:{
        Attendance,personInfo,leaveRecord,Trainning,Salary,warningData
    },
    computed:{
        pid(){
            return this.$route.query.id
        }
    }
}
</script>