<template>
    <section>
        <a-row v-if="recordsMap.length" type="flex" :gutter="20" class="list-card-container">
            <a-col :span="6" v-for="(item,key) in recordsMap" :key="key" class="m-t-16 list-item-wrap">
                <a-row class="list-card-bg" :title="item.remarks" style="cursor:default">
                    <a-row class="list-wrap">
                        <a-col style="margin-top:-10px">
                            <a-row class="list-item m-b-6"  :style="{color:item.status == 2 ? '#989898' : '#FF555E'}">
                                {{item.abnormalName}}
                            </a-row>
                            <a-row class="list-item">
                                截止日期：{{item.endTime}}
                            </a-row>

                            <a-row class="list-item" >
                                {{item.remarks}}
                            </a-row>
                        </a-col>
                    </a-row>
                   
                </a-row>
            </a-col>
        </a-row>
        <empty v-else :height="430" />
        <section class="pagination-container">
            <a-pagination size="default" :current="pageNum" :total="total" hide-on-single-page @change="changePageNum" />
        </section>
    </section>
</template>


<script>
import empty from '@/components/empty'
import moment from "moment"
export default {
    data(){
        return{
            total:0,
            pageNum:1,
            recordsMap:[]
        }
    },
    components:{empty},
    props:['personnelId'],
    created(){
        this.queryAbnormalList()
    },
    computed:{
        pid(){
            return this.$route.query.id || this.personnelId
        }
    },
    methods:{
        queryAbnormalList(){
            let params = {
                personnelId:this.pid,
                current:this.pageNum
            }
            this.$api.queryAbnormalList(params).then(res =>{
                if(res.code === 200){
                    this.recordsMap = (res.data && res.data.records) || []
                    this.recordsMap.forEach(item =>{
                        item.endTime = item.endTime ? moment(item.endTime).format('YYYY-MM-DD') : ''
                    })
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        changePageNum(p){
            this.pageNum = p
            this.queryAbnormalList()
        }
    }
}
</script>