<template>
    <section>
        <section class="info-container">
            <section class="info-item-wrap">
                <p class="info-title m-t-16">基础信息</p>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">举办单位</a-col>
                            <a-col class="val">{{trainInfo.companyName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">培训种类</a-col>
                            <a-col class="val">{{trainInfo.typeName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">培训地点</a-col>
                            <a-col class="val">{{trainInfo.addr}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">培训日期</a-col>
                            <a-col class="val">
                                <span class="m-r-8">{{trainInfo.dateStartTime}}</span>
                                <!-- <span class="m-r-8">-</span>
                                <span>{{trainInfo.dateEndTime}}</span> -->
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">开始时间</a-col>
                            <a-col class="val">{{trainInfo.startTime}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">结束时间</a-col>
                            <a-col class="val">{{trainInfo.endTime}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">上传人</a-col>
                            <a-col class="val">{{trainInfo.createByName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">上传时间</a-col>
                            <a-col class="val">{{trainInfo.createTime}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">培训标题</a-col>
                            <a-col class="val">{{trainInfo.title}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">培训内容</a-col>
                            <a-col class="val">{{trainInfo.content}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">培训照片</a-col>
                            <a-col class="val upload-preview-wrap">
                                        <a class="file-item m-r-10" v-for="(item,ind) in trainInfo.photo" :key="ind">
                                            <preview-image :data="item" :size="100" />
                                        </a>
                                    </a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="23">
                        <a-row class="info-item">
                            <a-col class="key">培训人员</a-col>
                            <a-col class="val">
                                <section class="list-table-container m-t-18">
                                    <a-table size="small" :columns="columns" :data-source="personnelLists" :pagination="{total:personnelLists.length,hideOnSinglePage:true}"></a-table>
                                </section>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
                
            </section>
        </section>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'所属单位',dataIndex:'companyName',align:'center',key:'companyName'},
    {title:'姓名',dataIndex:'personName',align:'center',key:'personName'},
    {title:'人员类型',dataIndex:'personTypeName',align:'center',key:'personTypeName'},
    {title:'职务/工种',dataIndex:'jobTypeName',align:'center',key:'jobTypeName'}
]
import previewImage from '@/components/previewImage'
export default {
    data(){
        return{
            columns,
            trainInfo:{},
            personnelLists:[]
        }
    },
    components:{previewImage},
    props:["trainId"],
    computed:{
        tid(){
            return this.$route.query.id
        },
        companyName(){
            return this.$route.query.companyName
        }
    },
    created(){
        
    },
    mounted(){
        this.querySafeEducationDetails()
    },
    methods:{
        querySafeEducationDetails(){
            
            this.$api.querySafeEducationDetails(this.trainId || this.tid).then(res =>{
                if(res.code === 200){
                    this.trainInfo = res.data || {}
                    // this.trainInfo.photo = this.trainInfo.photo ? JSON.parse(this.trainInfo.photo) : []
                    this.trainInfo.photo = this.trainInfo.photo ? this.trainInfo.photo.split(',') : []
                    this.trainInfo.companyName = this.trainInfo.companyName ? this.trainInfo.companyName : this.companyName
                    this.personnelLists = this.trainInfo.persons || [];
                    console.log(this.personnelLists)
                    this.personnelLists.forEach((item,ind) =>{
                        item.key = ind +1
                        item.personTypeName = item.personType
                    })
                }
            })
        }
    }
}
</script>