<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item>
                     <a-input-group compact>
                        <a-select style="width: 60px;margin-right:2px" v-model="dataType" @change="changeDateType">
                            <a-select-option :value="1">年</a-select-option>
                            <a-select-option :value="2">月</a-select-option>
                        </a-select>
                        <a-date-picker v-if="dataType == 1" mode="year" v-model="searchInfo.payYear" format='YYYY' :open="yearShowOne" @openChange="openChangeOne" @panelChange="panelChangeOne" class="search-select" />
                        <a-month-picker v-else v-model="searchInfo.payMonth" format='YYYY-MM' class="search-select" />
                    </a-input-group>
                </a-form-item>
                
               
                <a-form-item>
                    <a-button class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
        </section>
        <!-- <a-row class="m-t-18">
            <span class="m-r-14">发放次数：{{total}}次</span>
            <span class="m-r-14">应发总额：{{totalStatistics.payGrossAmount || 0}}元</span>
            <span>实发总额：{{totalStatistics.payNetAmount || 0}}元</span>
        </a-row> -->

        <section class="list-table-container m-t-10">
            <a-row class="total-data-wrap m-b-10">
                    <a-col class="item">
                        <span class="key">发放次数：</span>
                        <span class="val">{{total|| 0}}</span>
                    </a-col>
                    <a-col class="item">
                        <span class="key">应发总额(元)：</span>
                        <span class="val">{{totalStatistics.payGrossAmount || 0}}</span>
                    </a-col>
                    <a-col class="item">
                        <span class="key">实发总额(元)：</span>
                        <span class="val">{{totalStatistics.payNetAmount || 0}}</span>
                    </a-col>
                </a-row>
            <a-table size="small" :columns="columns" :data-source="listMap" :pagination="{total,hideOnSinglePage:true}" @change="changePageNum"></a-table>
        </section>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'月份',dataIndex:'payMonth',align:'center',key:'payMonth'},
    {title:'姓名',dataIndex:'personnelName',align:'center',key:'personnelName'},
    {title:'身份证号',dataIndex:'idCard',align:'center',key:'idCard'},
    {title:'单位名称',dataIndex:'companyName',align:'center',key:'companyName'},
    {title:'劳务班组',dataIndex:'teamName',align:'center',key:'teamName'},
    {title:'结算类型',dataIndex:'typeName',align:'center',key:'typeName'},
    {title:'天数',dataIndex:'workDay',align:'center',key:'workDay'},
    {title:'工时',dataIndex:'workTime',align:'center',key:'workTime'},
    {title:'加班工时',dataIndex:'overTime',align:'center',key:'overTime'},
    {title:'加班工资',dataIndex:'overtimeSalary',align:'center',key:'overtimeSalary'},
    {title:'工资合计',dataIndex:'totalSalary',align:'center',key:'totalSalary'},
    {title:'开户行',dataIndex:'payBankname',align:'center',key:'payBankname'},
    {title:'银行卡号',dataIndex:'payCardnum',align:'center',key:'payCardnum'}
]
export default {
    data(){
        return{
            columns,
            searchInfo:{
                payMonth:null,
                payYear:null
            },
            total:0,
            pageNum:1,
            listMap:[],
            yearShowOne:false,
            dataType:1,
            totalStatistics:{}
        }
    },
    props:["personnelId"],
    mounted(){
        this.queryCompanyPaySalaryPersonnelList()
        this.queryCompanyPaySalaryTotalStatistics()
    },
    methods:{
        openChangeOne(status){
            if(status){
                this.yearShowOne =  true
            }
        },
        panelChangeOne(value){
            this.searchInfo.payYear = value
            this.yearShowOne = false
        },
        changeDateType(){
            this.searchInfo.payYear = this.dataType == 1 ? this.searchInfo.payYear : null
            this.searchInfo.payMonth = this.dataType == 2 ? this.searchInfo.payMonth : null
        },
        //薪资统计
        queryCompanyPaySalaryTotalStatistics(){
            this.$api.queryCompanyPaySalaryTotalStatistics({personnelId:this.personnelId}).then(res =>{
                if(res.code === 200){
                    this.totalStatistics = res.data || {}
                }
            })
        },
        queryCompanyPaySalaryPersonnelList(){
            let params = {...this.searchInfo}
            params.current =this.pageNum
            params.personnelId = this.personnelId
            params.payYear = this.searchInfo.payYear ? this.searchInfo.payYear.format('YYYY') : ''
            params.payMonth = this.searchInfo.payMonth ? this.searchInfo.payMonth.format('YYYY-MM') : ''
            this.$api.queryCompanyPaySalaryPersonnelList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = (res.data && res.data.records) || []
                    this.listMap.forEach((item,ind) =>{
                        item.key = ind +1
                    })
                    this.total = (res.data && res.data.total) || 0
                }
            })
        },
        reset(){
            this.searchInfo = {
                payYear:null,
                payMonth:null
            }
            this.pageNum = 1
            this.queryCompanyPaySalaryPersonnelList()
        },
        search(){
            this.pageNum = 1
            this.queryCompanyPaySalaryPersonnelList()
        },
        changePageNum(p){
            this.pageNum = p.current
            this.queryCompanyPaySalaryPersonnelList()
        },
    }
}
</script>