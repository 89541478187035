<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="考勤日期">
                    <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
                </a-form-item>
                
                <a-form-item label="考勤状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">正常</a-select-option>
                        <a-select-option :value="1">迟到</a-select-option>
                        <a-select-option :value="2">缺卡</a-select-option>
                        <a-select-option :value="3">早退</a-select-option>
                        <a-select-option :value="4">请假</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-button class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <!-- <a-row class="right-action-panel m-t-14">
                <a-button @click="showPositionModal" class="item">添加职务</a-button>
            </a-row> -->
        </section>

        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="listMap" :pagination="{total,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                <a-row slot="signInPhoto" slot-scope="text">
                    <previewImage :data="text" :size="40" />
                </a-row>
                <span slot="signInState" slot-scope="text">{{text === 0 ?'正常' : text === 1 ? '迟到' : text === 2 ? '缺卡' : text === 3 ? '早退': text === 4 ? '请假' : ''}}</span>
                <span slot="signOutState" slot-scope="text">{{text === 0 ?'正常' : text === 1 ? '迟到' : text === 2 ? '缺卡' : text === 3 ? '早退': text === 4 ? '请假' : ''}}</span>
                <span slot="deviceType" slot-scope="text">{{text === 1 ?'门禁设备' : '考勤设备'}}</span>
                <a-row slot="signOutPhoto" slot-scope="text">
                    <previewImage :data="text" :size="40" />
                </a-row>
            </a-table>
        </section>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'姓名',dataIndex:'personnelName',align:'center',key:'personnelName'},
    {title:'职务',dataIndex:'jobName',align:'center',key:'jobName'},
    {title:'设备类型',dataIndex:'deviceType',align:'center',key:'deviceType',scopedSlots:{customRender: "deviceType"}},
    {title:'设备名称',dataIndex:'deviceName',align:'center',key:'deviceName'},
    {title:'考勤日期',dataIndex:'attendanceDate',align:'center',key:'attendanceDate'},
    {title:'上班签到时间',dataIndex:'signInTime',align:'center',key:'signInTime'},
    {title:'签到状态',dataIndex:'signInState',align:'center',key:'signInState',scopedSlots:{customRender: "signInState"}},
    {title:'签到照片',dataIndex:'signInPhoto',align:'center',key:'signInPhoto',scopedSlots:{customRender: "signInPhoto"}},
    {title:'下班签到时间',dataIndex:'signOutTime',align:'center',key:'signOutTime'},
    {title:'签到状态',dataIndex:'signOutState',align:'center',key:'signOutState',scopedSlots:{customRender: "signOutState"}},
    {title:'签到照片',dataIndex:'signOutPhoto',align:'center',key:'signOutPhoto',scopedSlots:{customRender: "signOutPhoto"}},
]
import previewImage from '@/components/previewImage'
export default {
    data(){
        return{
            columns,
            pageNum:1,
            total:0,
            listMap:[],
            searchInfo:{
                endDate:[]
            }
        }
    },
    components:{previewImage},
    props:['personnelId'],
    created(){
        this.queryPersonnelAttendanceRecordList()
    },
    methods:{
        queryPersonnelAttendanceRecordList(){
            let params = {...this.searchInfo}
            params.startDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : ''
            params.endDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
            params.current = this.pageNum
            params.personnelIds = this.personnelId
            this.$api.queryPersonnelAttendanceRecordList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                    this.listMap.forEach((item,ind) =>{
                        item.key = ind +1
                    })
                }
            })
        },
        changePageNum(p){
            this.pageNum = p.current
            this.queryPersonnelAttendanceRecordList()
        },
        reset(){
            this.searchInfo = {
                endDate:[]
            }

            this.pageNum = 1
            this.queryPersonnelAttendanceRecordList()
        },
        search(){
            this.pageNum = 1
            this.queryPersonnelAttendanceRecordList()
        }
    }
}
</script>