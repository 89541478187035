<template>
  <section>
    <section class="info-container m-t-18">
      <section class="info-item-wrap">
        <p class="info-title">单位信息</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">所属单位</a-col>
              <a-col class="val">{{ personnelInfo.companyName }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">统一信用代码</a-col>
              <a-col class="val">{{ personnelInfo.socialCreditCode }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">单位类型</a-col>
              <a-col class="val">{{ personnelInfo.companyTypeName }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <section class="info-item-wrap">
        <p class="info-title">人员信息</p>
        <a-row class="m-t-18">
          <!-- <a-col :span="24">
                        <a-row class="info-item">
                            <a-col class="key">人脸照片</a-col>
                            <a-col class="val">
                                <img style="width:80px;height:100px;border-radius:4px" :src="personnelInfo.avatar!=''?picServer+personnelInfo.avatar:baseImage" alt="">
                            </a-col>
                        </a-row>
                    </a-col> -->
          <a-col :span="12">
            <a-row class="info-item">
              <a-col class="key">人脸照片</a-col>
              <a-col class="val">
                <viewer
                  v-if="
                    personnelInfo.avatar &&
                    personnelInfo.avatar.includes('uploads')
                  "
                  :images="[{ src: picServer + personnelInfo.avatar }]"
                >
                  <img
                    style="width: 80px; height: 100px; border-radius: 4px"
                    :src="picServer + personnelInfo.avatar"
                    alt
                  />
                </viewer>
                <viewer v-else :images="[{ src: personnelInfo.avatar }]">
                  <img
                    style="width: 80px; height: 100px; border-radius: 4px"
                    :src="personnelInfo.avatar"
                    alt
                  />
                </viewer>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="12">
            <a-row class="info-item">
              <a-col class="key">身份证照片</a-col>
              <a-col class="val">
                <img
                  v-if="
                    personnelInfo.idCardPhoto &&
                    personnelInfo.idCardPhoto.includes('uploads')
                  "
                  style="width: 120px; height: 100px; border-radius: 4px"
                  :src="picServer + personnelInfo.idCardPhoto"
                  alt
                />
                <img
                  v-else
                  style="width: 120px; height: 100px; border-radius: 4px"
                  :src="personnelInfo.idCardPhoto"
                  alt
                />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">人员姓名</a-col>
              <a-col class="val">{{ personnelInfo.personnelName }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">身份证号</a-col>
              <a-col class="val">{{ personnelInfo.idCard }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">签发机关</a-col>
              <a-col class="val">{{ personnelInfo.issuingAuthority }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">有效日期</a-col>
              <a-col class="val"
                >{{ personnelInfo.startValid }} -
                {{
                  personnelInfo.endValid ? personnelInfo.endValid : "长期"
                }}</a-col
              >
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">民族</a-col>
              <a-col class="val">{{ personnelInfo.nation }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">性别</a-col>
              <a-col class="val">{{
                personnelInfo.sex === 1
                  ? "男"
                  : personnelInfo.sex === 2
                  ? "女"
                  : ""
              }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">户籍地址</a-col>
              <a-col class="val">{{ personnelInfo.residenceAddress }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">政治面貌</a-col>
              <a-col class="val">{{
                personnelInfo.residenceAddress === 1
                  ? "党员"
                  : personnelInfo.residenceAddress === 0
                  ? "非党员"
                  : ""
              }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">联系电话</a-col>
              <a-col class="val">{{ personnelInfo.phoneNumber }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">婚姻状态</a-col>
              <a-col class="val">{{
                personnelInfo.maritalStatus === 1
                  ? "已婚"
                  : personnelInfo.maritalStatus === 0
                  ? "未婚"
                  : ""
              }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">教育水平</a-col>
              <a-col class="val">
                {{
                  personnelInfo.educationLevel === 1
                    ? "初中"
                    : personnelInfo.educationLevel === 2
                    ? "高中"
                    : personnelInfo.educationLevel === 3
                    ? "中专"
                    : personnelInfo.educationLevel === 4
                    ? "大专"
                    : personnelInfo.educationLevel === 5
                    ? "本科"
                    : personnelInfo.educationLevel === 6
                    ? "硕士"
                    : personnelInfo.educationLevel === 7
                    ? "博士"
                    : ""
                }}
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">详细地址</a-col>
              <a-col class="val">{{ personnelInfo.residenceAddress }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">开户行</a-col>
              <a-col class="val">{{ personnelInfo.payBankname }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">银行卡号</a-col>
              <a-col class="val">{{ personnelInfo.payCardnum }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">所属支行</a-col>
              <a-col class="val">{{ personnelInfo.payCardnum }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">是否标记</a-col>
              <a-col class="val">{{
                personnelInfo.liveBuiltBureau === 1 ? "是" : "否"
              }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">备注说明</a-col>
              <a-col class="val">{{ personnelInfo.remarks }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <!-- 紧急联系人 -->
      <section class="info-item-wrap">
        <p class="info-title">紧急联系人</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">联系人姓名</a-col>
              <a-col class="val">{{ personnelInfo.emergencyContact }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">联系人电话</a-col>
              <a-col class="val">{{ personnelInfo.contactNumber }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <!-- 班组信息 -->
      <section class="info-item-wrap" v-if="personnelInfo.personnelType == 3">
        <p class="info-title">班组信息</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">班组名称</a-col>
              <a-col class="val">{{ personnelInfo.teamName }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">是否组长</a-col>
              <a-col class="val">{{
                personnelInfo.isLeader === 1 ? "是" : "否"
              }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <!-- 工种信息 -->
      <section class="info-item-wrap" v-if="personnelInfo.personnelType == 3">
        <p class="info-title">工种信息</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">工种名称</a-col>
              <a-col class="val">{{ personnelInfo.positionWorkName }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <!-- 职务信息 -->
      <section
        class="info-item-wrap"
        v-if="
          personnelInfo.personnelType == 1 || personnelInfo.personnelType == 2
        "
      >
        <p class="info-title">职务信息</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">职务名称</a-col>
              <a-col class="val">{{ personnelInfo.positionWorkName }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <section class="info-item-wrap">
        <p class="info-title">出入场信息</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">登记时间</a-col>
              <a-col class="val">{{ personnelInfo.inDate }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">退场时间</a-col>
              <a-col class="val">{{ personnelInfo.outDate }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <!-- 资格证书 -->
      <section
        class="info-item-wrap m-t-10"
        v-if="
          personnelInfo.personnelType == 1 || personnelInfo.personnelType == 2
        "
      >
        <p class="info-title">资格证书</p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="certificate.columns"
            :data-source="certificate.data"
            :pagination="false"
          >
            <a-row v-if="list.certUrl" slot="certUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
          </a-table>
        </section>
      </section>
      <!-- 特种作业证书 -->
      <section
        class="info-item-wrap m-t-10"
        v-if="personnelInfo.personnelType == 3"
      >
        <p class="info-title">特种作业证书</p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="certificate.specialOperationColumns"
            :data-source="certificate.data"
            :pagination="false"
          >
            <a-row v-if="list.certUrl" slot="certUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
          </a-table>
        </section>
      </section>

      <!-- 证照管理 -->
      <section class="info-item-wrap m-t-10">
        <p class="info-title">证照管理</p>

        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="licence.columns"
            :data-source="licence.data"
            :pagination="false"
          >
            <a-row v-if="list.fileUrl" slot="fileUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
          </a-table>
        </section>
      </section>

      <!-- 人员合同 -->
      <section class="info-item-wrap m-t-10">
        <p class="info-title">人员合同</p>
        <a-row class="m-t-18">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">合同编号</a-col>
              <a-col class="val">{{ personnelInfo.contractNo }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">合同开始时间</a-col>
              <a-col class="val">{{ personnelInfo.handleDate }}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">合同结束时间</a-col>
              <a-col class="val">{{ personnelInfo.endDate }}</a-col>
            </a-row>
          </a-col>
        </a-row>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            :columns="contract.columns"
            :data-source="contract.data"
            :pagination="false"
          >
            <a-row v-if="list.fileUrl" slot="fileUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
          </a-table>
        </section>
      </section>

      <!-- 体检信息 -->
      <section class="info-item-wrap m-t-10">
        <p class="info-title">体检信息</p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="test.columns"
            :data-source="test.data"
            :pagination="false"
          >
            <a-row
              v-if="list.medicalUrl"
              slot="medicalUrl"
              slot-scope="text, list"
            >
              <preview-image :data="text" :size="44" />
            </a-row>
          </a-table>
        </section>
      </section>

      <!-- 从业信息 -->
      <section class="info-item-wrap m-t-10">
        <p class="info-title">从业信息</p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="workExperiences.columns"
            :data-source="workExperiences.data"
            :pagination="false"
          ></a-table>
        </section>
      </section>

      <!-- 资料文件 -->
      <section class="info-item-wrap m-t-10">
        <p class="info-title">资料文件</p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="fileData.columns"
            :data-source="fileData.data"
            :pagination="false"
          >
            <a-row v-if="list.fileUrl" slot="fileUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
          </a-table>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
const certificateColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "专业名称",
    dataIndex: "professionName",
    align: "center",
    key: "professionName",
  },
  {
    title: "证书名称",
    dataIndex: "jobTitle",
    align: "center",
    key: "jobTitle",
  },
  {
    title: "证书编号",
    dataIndex: "certNumber",
    align: "center",
    key: "certNumber",
  },
  {
    title: "到期时间",
    dataIndex: "certEndTime",
    align: "center",
    key: "certEndTime",
  },
  {
    title: "证书附件",
    dataIndex: "certUrl",
    align: "center",
    key: "certUrl",
    scopedSlots: { customRender: "certUrl" },
    width: "120px",
  },
];
const specialOperationColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "证件号",
    dataIndex: "certNumber",
    align: "center",
    key: "certNumber",
  },
  {
    title: "初领时间",
    dataIndex: "certFirstTime",
    align: "center",
    key: "certFirstTime",
  },
  {
    title: "有效日期",
    dataIndex: "certStartTime",
    align: "center",
    key: "certStartTime",
  },
  {
    title: "证书附件",
    dataIndex: "certUrl",
    align: "center",
    key: "certUrl",
    scopedSlots: { customRender: "certUrl" },
  },
];
const licenceColumns = [
  {
    title: "序号",
    dataIndex: "key",
    align: "center",
    key: "key",
    width: "120px",
  },
  {
    title: "材料名称",
    dataIndex: "fileMaterialName",
    align: "center",
    key: "fileMaterialName",
  },
  {
    title: "附件",
    dataIndex: "fileUrl",
    align: "center",
    key: "fileUrl",
    scopedSlots: { customRender: "fileUrl" },
    width: "120px",
  },
];
const testColumns = [
  {
    title: "序号",
    dataIndex: "key",
    align: "center",
    key: "key",
    width: "120px",
  },
  {
    title: "体检时间",
    dataIndex: "medicalDate",
    align: "center",
    key: "medicalDate",
  },
  {
    title: "结论",
    dataIndex: "medicalResult",
    align: "center",
    key: "medicalResult",
  },
  {
    title: "附件",
    dataIndex: "medicalUrl",
    align: "center",
    key: "medicalUrl",
    scopedSlots: { customRender: "medicalUrl" },
    width: "120px",
  },
];
const workExperiencesColumns = [
  {
    title: "序号",
    dataIndex: "key",
    align: "center",
    key: "key",
    width: "120px",
  },
  {
    title: "参与项目名称",
    dataIndex: "siteName",
    align: "center",
    key: "siteName",
  },
  {
    title: "原工作单位名称",
    dataIndex: "workUnitName",
    align: "center",
    key: "workUnitName",
  },
  {
    title: "进场日期",
    dataIndex: "startDate",
    align: "center",
    key: "startDate",
  },
  { title: "退场日期", dataIndex: "endDate", align: "center", key: "endDate" },
  {
    title: "职务名称",
    dataIndex: "workType",
    align: "center",
    key: "workType",
  },
  { title: "证明人", dataIndex: "workKind", align: "center", key: "workKind" },
];
const fileColumns = [
  {
    title: "序号",
    dataIndex: "key",
    align: "center",
    key: "key",
    width: "120px",
  },
  {
    title: "文件名称",
    dataIndex: "fileName",
    align: "center",
    key: "fileName",
  },
  {
    title: "附件",
    dataIndex: "fileUrl",
    align: "center",
    key: "fileUrl",
    scopedSlots: { customRender: "fileUrl" },
    width: "120px",
  },
];
import previewImage from "@/components/previewImage";
export default {
  data() {
    return {
      personnelInfo: {},
      //证书
      certificate: {
        columns: certificateColumns,
        specialOperationColumns,
        data: [],
      },
      //证照
      licence: {
        columns: licenceColumns,
        data: [
          { key: 1, fileMaterialName: "身份证照片正面", fileUrl: "" },
          { key: 2, fileMaterialName: "身份证照片反面", fileUrl: "" },
          { key: 3, fileMaterialName: "三级安全教育证明", fileUrl: "" },
        ],
      },
      //合同
      contract: {
        columns: licenceColumns,
        data: [{ key: 1, fileMaterialName: "合同附件", fileUrl: "" }],
      },
      //体检信息
      test: {
        columns: testColumns,
        data: [],
      },
      //工作经历
      workExperiences: {
        columns: workExperiencesColumns,
        data: [],
      },
      //资料文件
      fileData: {
        columns: fileColumns,
        data: [],
      },
      baseImage: require("@/assets/image/personnel.png"),
    };
  },
  components: { previewImage },
  props: ["personnelId"],
  created() {
    this.queryPersonnelDetails();
  },
  computed: {
    picServer() {
      return this.$store.state.picServer;
    },
  },
  methods: {
    queryPersonnelDetails() {
      this.$api.queryPersonnelDetails({ id: this.personnelId }).then((res) => {
        if (res.code === 200) {
          let _data = res.data || {};
          this.personnelInfo = _data.personnelInfo;
          this.personnelInfo.companyName = _data.companyName;
          this.personnelInfo.companyTypeName = _data.companyTypeName;
          this.personnelInfo.socialCreditCode = _data.socialCreditCode;
          this.personnelInfo.positionWorkName = _data.positionWorkName;
          this.personnelInfo.teamName = _data.teamName;
          this.personnelInfo.isLeader = _data.isLeader;
          this.personnelInfo.avatar = this.personnelInfo.avatar
            ? this.personnelInfo.avatar
            : require("../../../../../assets/image/personnel.png");
          this.personnelInfo.idCardPhoto = this.personnelInfo.idCardPhoto
            ? this.personnelInfo.idCardPhoto
            : require("../../../../../assets/image/iDCard.png");
          //证书
          this.certificate.data = _data.personnelCert || [];
          this.certificate.data.forEach((item, ind) => {
            item.key = ind + 1;
          });
          //证照
          if (_data.personnelFile && _data.personnelFile.length) {
            _data.personnelFile.forEach((item) => {
              this.licence.data.forEach((v) => {
                if (v.fileMaterialName === item.fileMaterialName) {
                  v.fileUrl = item.fileUrl;
                }
              });
            });
          }
          //this.licence.data = _data.personnelFile || []

          //合同
          let personntlContract = _data.personntlContract || {};
          this.personnelInfo.contractNo =
            personntlContract.personnelContract.contractNo;
          this.personnelInfo.handleDate =
            personntlContract.personnelContract.handleDate;
          this.personnelInfo.endDate =
            personntlContract.personnelContract.endDate;
          if (
            personntlContract.personnelFiles &&
            personntlContract.personnelFiles.length
          ) {
            personntlContract.personnelFiles.forEach((item) => {
              this.contract.data.forEach((v) => {
                if (v.fileMaterialName === item.fileMaterialName) {
                  v.fileUrl = item.fileUrl;
                }
              });
            });
          }

          //体检信息
          this.test.data = _data.personntlMedical || [];
          this.test.data.forEach((item, ind) => {
            item.key = ind + 1;
          });

          //工作经历
          this.workExperiences.data = _data.workExperience || [];
          this.workExperiences.data.forEach((item, ind) => {
            item.key = ind + 1;
          });

          //资料文件
          this.fileData.data = _data.documentList || [];
          this.fileData.data.forEach((item, ind) => {
            item.key = ind + 1;
          });
        }
      });
    },
  },
};
</script>